/*
All of our tile source information in one place.
*/

import * as constants from './constants.js';

const dynamicTileSource = {
    id: 'eroadmap', // can be anything; this is for us to keep track of sources and layers
    url: constants.dynamicTilesBase + '/eroadmapdata.demand/{z}/{x}/{y}.pbf',
};

const staticTileSource = {
    id: 'eroadmap_static',
    url: constants.staticTilesBase + '/demand/2030/{z}/{x}/{y}.pbf',
};

const hosting_capacity_detail = {
    id: 'eroadmapdata.hosting_capacity_combined',
    url:
        constants.dynamicTilesBase +
        '/eroadmapdata.hosting_capacity_combined/{z}/{x}/{y}.pbf',
};

const ev_charging = {
    id: 'eroadmapdata.ev_charging_stations',
    url:
        constants.dynamicTilesBase +
        '/eroadmapdata.ev_charging_stations/{z}/{x}/{y}.pbf',
};

const justice40_static = {
    id: 'justice40',
    url: constants.staticTilesBase + '/justice40/{z}/{x}/{y}.pbf',
};

const justice40_dynamic = {
    id: 'eroadmapdata.justice40',
    url: constants.dynamicTilesBase + '/eroadmapdata.justice40/{z}/{x}/{y}.pbf',
};

const acs_households_static = {
    id: 'acs_households_data',
    url: constants.staticTilesBase + '/acs_households_data/{z}/{x}/{y}.pbf',
};

const acs_households_dynamic = {
    id: 'eroadmapdata.acs_households_data',
    url:
        constants.dynamicTilesBase +
        '/eroadmapdata.acs_households_data/{z}/{x}/{y}.pbf',
};

const truck_stops = {
    id: 'jlaw_truck_stops_data_shapefi-7kpepn',
    url: 'mapbox://eroadmap.5g88mozp',
};

const utility_service_areas = {
    id: 'utility_service_areas-1mmwjt',
    url: 'mapbox://eroadmap.3jl96gjr',
};

export {
    acs_households_dynamic,
    acs_households_static,
    dynamicTileSource,
    ev_charging,
    hosting_capacity_detail,
    justice40_dynamic,
    justice40_static,
    staticTileSource,
    truck_stops,
    utility_service_areas,
};
